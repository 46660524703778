// extracted by mini-css-extract-plugin
export var lbContainerOuter = "v_tz";
export var lbContainerInner = "v_tB";
export var movingForwards = "v_tC";
export var movingForwardsOther = "v_tD";
export var movingBackwards = "v_tF";
export var movingBackwardsOther = "v_tG";
export var lbImage = "v_tH";
export var lbOtherImage = "v_tJ";
export var prevButton = "v_tK";
export var nextButton = "v_tL";
export var closing = "v_tM";
export var appear = "v_tN";